import React, { useEffect, useState } from "react";
import { questions, finalResults } from "../config";
import Logo from "./assets/baes-logo.png";
import QrCode from "./assets/qr-code.png";
import { AnimatePresence, motion } from "framer-motion";
import VideoBackground from "./assets/background.mp4";
const Questions = ({ onEnd }: { onEnd: () => void }) => {
  const [currentQuestion, setCurrentQuestion] = useState<any | null>(null);
  const [lastScreen, setLastScreen] = useState<any | null>(null);
  const [showTransition, setShowTransition] = useState(true);
  const changeQuestion = (goTo: any) => {
    if (goTo.type === "question") {
      const nextQuestion = questions.filter(
        (question: any) => question.id === goTo.id
      );
      setCurrentQuestion({ ...nextQuestion[0] });
    } else {
      const finalScreen = finalResults.filter(
        (result: any) => result.id === goTo.id
      );
      setLastScreen({ ...finalScreen[0] });
    }
  };
  useEffect(() => {
    if (currentQuestion === null) {
      setCurrentQuestion(questions[0]);
    }
  }, [currentQuestion]);
  useEffect(() => {
    if (lastScreen && showTransition) {
      setTimeout(() => {
        console.log('transitioning')
        setShowTransition(false);
      }, 2500);
    }
  }, [lastScreen]);
  const endGame = () => {
    setLastScreen(null);
    onEnd();
  };
  return (
    <>
      {!lastScreen && currentQuestion && (
        <div className={`question ${currentQuestion.id}`}>
          <img src={Logo} alt="logo" width="340px" className="z-10" />
          <img
            src={`images/${currentQuestion.id}.jpg`}
            className="question-bg"
            alt="logo"
          />
          <div className="question-inner flex-grow">
            <h2 className="text-white">{currentQuestion.question}</h2>
            <div className="answers text-white">
              {currentQuestion.answers.map((answer: any, indx: number) => (
                <button
                  key={indx}
                  onClick={() => {
                    setTimeout(() => {
                      changeQuestion(answer.goto);
                    }, 600);
                  }}
                  className="answer"
                >
                  {answer.text}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
      {lastScreen && showTransition && (
        <div className="loading-screen">
          <video autoPlay loop muted  className="background">
            <source src={VideoBackground} type="video/mp4" />
          </video>
          <h2 className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-8xl text-white z-10 w-full text-center">Based on your answers,<br />we think you could consider…</h2>
        </div>
      )}
      {lastScreen && !showTransition && (
        <div className={`results`}>
          <img
            src={`images/${lastScreen.id}.jpg`}
            className="question-bg"
            alt="logo"
          />
          <div className={`results-inner ${lastScreen.id}`}>
            <img
              src={Logo}
              alt="logo"
              width="340px"
              className="absolute right-20 top-16"
            />
            <div className="flex-grow">
              <h2 className="text-black">{lastScreen.title}</h2>
              <div
                className="text-black"
                dangerouslySetInnerHTML={{ __html: lastScreen.text }}
              />
            </div>
            <div className="flex flex-row justify-between items-center w-full">
              <div className="qr-holder w-1/2 flex flex-row items-center bg-blue-550/70 gap-8 pl-8 pr-4 py-4">
                <div className="flex-grow">
                  <p>
                    Scan the QR code to learn more about BAE Systems and our
                    career paths.
                  </p>
                  <p>You can also speak to our teams in Hall 1 and Hall 3</p>
                </div>
                <img src={QrCode} alt="qr code" width={175} />
              </div>
              <button className="restart" onClick={endGame}>
                Play again
                <svg
                  id="Layer_4"
                  data-name="Layer 4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100.6 102.83"
                >
                  <g id="Lose">
                    <path
                      className="cls-4"
                      d="M5.44,66.44c-.42-1.26-1.78-1.93-3.04-1.51-1.25.43-1.92,1.79-1.5,3.05,3.48,10.27,10.11,19.08,18.73,25.27,8.39,6.03,18.67,9.58,29.76,9.58,14.14,0,26.94-5.76,36.21-15.06,9.27-9.3,15-22.16,15-36.36s-5.73-27.05-15-36.36C76.33,5.76,63.53,0,49.39,0c-10.86,0-20.95,3.41-29.25,9.22-6.29,4.4-11.54,10.18-15.33,16.9v-8.82c0-1.33-1.07-2.41-2.4-2.41s-2.4,1.08-2.4,2.41v17.49c0,1.33,1.07,2.41,2.4,2.41h17.42c1.33,0,2.4-1.08,2.4-2.41s-1.07-2.41-2.4-2.41H7.01c3.47-7.77,8.99-14.41,15.86-19.22,7.51-5.25,16.65-8.34,26.51-8.34,12.82,0,24.42,5.22,32.82,13.65,8.4,8.43,13.59,20.08,13.59,32.95s-5.19,24.52-13.59,32.95c-8.4,8.43-20,13.65-32.82,13.65-10.07,0-19.39-3.21-26.99-8.68-7.81-5.61-13.81-13.59-16.96-22.9h0Z"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Questions;
