export const questions = [
  // {
  //   question: "What's your spirit animal?",
  //   id: "fq-1",
  //   answers: [
  //     {
  //       text: "Eagle",
  //       goto: {
  //         type: "question",
  //         id: "q-1",
  //       },
  //       id: "fq-1-a-1",
  //     },
  //     {
  //       text: "Dolphin",
  //       goto: {
  //         type: "question",
  //         id: "q-1",
  //       },
  //       id: "fq-1-a-2",
  //     },
  //     {
  //       text: "Wolf",
  //       goto: {
  //         type: "question",
  //         id: "q-1",
  //       },
  //       id: "fq-1-a-3",
  //     },
  //     {
  //       text: "Owl",
  //       goto: {
  //         type: "question",
  //         id: "q-1",
  //       },
  //       id: "fq-1-a-4",
  //     },
  //   ],
  // },
  {
    question: "How do you approach problem-solving in your daily life?",
    id: "q-1",
    answers: [
      {
        text: "I enjoy analysing data and finding logical solutions.",
        goto: {
          type: "question",
          id: "q-3",
        },
        id: "q-1-a-1",
      },
      {
        text: "I prefer creative and innovative approaches.",
        goto: {
          type: "question",
          id: "q-2",
        },
        id: "q-1-a-2",
      },
      {
        text: "I like collaborating with others to find solutions.",
        goto: {
          type: "question",
          id: "q-3",
        },
        id: "q-1-a-3",
      },
      {
        text: "I tend to follow established methods and procedures.",
        goto: {
          type: "question",
          id: "q-2",
        },
        id: "q-1-a-4",
      },
    ],
  },
  {
    question: "What type of environment do you thrive in the most?",
    id: "q-2",
    answers: [
      {
        text: "Fast-paced and dynamic settings with constant challenges.",
        goto: {
          type: "question",
          id: "q-3",
        },
        id: "q-2-a-1",
      },
      {
        text: "Structured and organized environments with clear processes.",
        goto: {
          type: "question",
          id: "q-3",
        },
        id: "q-2-a-2",
      },
      {
        text: "Collaborative and team-oriented atmospheres.",
        goto: {
          type: "question",
          id: "q-3",
        },
        id: "q-2-a-3",
      },
      {
        text: "Creative and open-minded spaces that encourage expression.",
        goto: {
          type: "question",
          id: "q-3",
        },
        id: "q-2-a-4",
      },
    ],
  },
  // {
  //   question:
  //     "If you could have dinner with any historical figure, who would it be?",
  //   id: "fq-2",
  //   answers: [
  //     {
  //       text: "Albert Einstein",
  //       goto: {
  //         type: "question",
  //         id: "q-4",
  //       },
  //       id: "fq-2-a-1",
  //     },
  //     {
  //       text: "Marie Curie",
  //       goto: {
  //         type: "question",
  //         id: "q-4",
  //       },
  //       id: "fq-2-a-2",
  //     },
  //     {
  //       text: "Leonardo da Vinci",
  //       goto: {
  //         type: "question",
  //         id: "q-4",
  //       },
  //       id: "fq-2-a-3",
  //     },
  //     {
  //       text: "William Shakespeare",
  //       goto: {
  //         type: "question",
  //         id: "q-4",
  //       },
  //       id: "fq-2-a-4",
  //     },
  //   ],
  // },
  {
    question: "How do you prefer to learn new things?",
    id: "q-3",
    answers: [
      {
        text: "Hands-on experience and learning by doing.",
        goto: {
          type: "question",
          id: "q-4",
        },
        id: "q-3-a-1",
      },
      {
        text: "Classroom instruction and theoretical knowledge.",
        goto: {
          type: "question",
          id: "q-4",
        },
        id: "q-3-a-2",
      },
      {
        text: "Engaging in discussions and group activities.",
        goto: {
          type: "question",
          id: "q-4",
        },
        id: "q-3-a-3",
      },
      {
        text: "Self-directed learning and research.",
        goto: {
          type: "question",
          id: "q-4",
        },
        id: "q-3-a-4",
      },
    ],
  },
  {
    question:
      "What type of projects or activities captivate your interest the most?",
    id: "q-4",
    answers: [
      {
        text: "Projects involving cutting-edge technology and innovation.",
        goto: {
          type: "final",
          id: "final-1",
        },
        id: "q-4-a-1",
      },
      {
        text: "Projects with a clear structure and defined processes.",
        goto: {
          type: "final",
          id: "final-2",
        },
        id: "q-4-a-2",
      },
      {
        text: "Collaborative projects that require effective communication.",
        goto: {
          type: "question",
          id: "q-5",
        },
        id: "q-4-a-3",
      },
      {
        text: "Projects related to language, literature, or cultural exchange.",
        goto: {
          type: "final",
          id: "final-3",
        },
        id: "q-4-a-4",
      },
    ],
  },
  {
    question: "How do you envision contributing to a team or organization?",
    id: "q-5",
    answers: [
      {
        text: "By providing strategic insights and leadership.",
        goto: {
          type: "final",
          id: "final-4",
        },
        id: "q-5-a-1",
      },
      {
        text: "By ensuring efficiency and adherence to established procedures.",
        goto: {
          type: "final",
          id: "final-5",
        },
        id: "q-5-a-2",
      },
      {
        text: "By fostering teamwork and effective communication.",
        goto: {
          type: "final",
          id: "final-1",
        },
        id: "q-5-a-3",
      },
      {
        text: "By sharing knowledge and facilitating learning experiences.",
        goto: {
          type: "final",
          id: "final-3",
        },
        id: "q-5-a-4",
      },
    ],
  },
];

export const finalResults = [
  {
    id: "final-1",
    title: "Supply Chain Apprenticeship Programme",
    text: "<p>Aimed at High School/Diploma graduates to develop high standards of professional skills.</p><p>Duration: 2 years</p><p>Exit Role: Purchasing and Supply Chain Management.</p>",
  },
  {
    id: "final-2",
    title: "Co-operative Training Programme",
    text: "<p>Aimed at undergraduate students, the programme offers essential practical experience in addition to classroom and laboratory-based teaching associated with their particular engineering or professional discipline.</p><p>Duration: xxx</p>",
  },
  {
    id: "final-3",
    title: "English Language Teachers",
    text: "<p>Aimed at motivated, high caliber university graduates holding a degree in English.</p><p>Duration: six-month training contract (including 16-week course)</p><p>Exit Role: English Language Teacher to support Royal Saudi Air Force Training Programs.</p>",
  },
  {
    id: "final-4",
    title: "Graduate Development Programme (GDP)",
    text: "<p>The Graduate Development Programme (GDP) supports business resource requirements by recruiting and developing talented Saudi nationals with challenging and stretching work experience.</p><p>Duration: 2 years</p><p>Exit Role: Placement into appropriate roles withing engineering or business functions.</p>",
  },
  {
    id: "final-5",
    title: "Saudi National Technical Training  Programe (SNTTP)",
    text: "<p>Aimed at High School/Diploma graduates and offering training in a variety of technical skills.</p><p>Duration: 5 years. Including 18 months of formal instruction and 3 years on the job training.</p><p>Exit role: A range of aircraft maintenance specializations:</p>",
  },
];

export const shuffleAnswers = (answers: any[]) => {
  const shuffledAnswers = [...answers].sort(() => Math.random() - 0.5);

  return shuffledAnswers;
};
