import React from "react";
import Logo from "./components/assets/baes-logo.png";
import { AnimatePresence, motion } from "framer-motion";
import Questions from "./components/questions";
import Carousel from "./components/carousel";

function App() {
  const [isRunning, setIsRunning] = React.useState(false);
  return (
    <div className="App">
      <AnimatePresence>
        {!isRunning && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="start"
          >
            <div className="start-inner z-10">
              <div className="text-center">
                <h1>What career is right for you?</h1>
                <h2>Play to find out.</h2>
              </div>
              <Carousel />
              <button onClick={() => setIsRunning(true)}>
                <svg
                  id="Layer_2"
                  data-name="Layer 2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 265.03 265.03"
                >
                  <g id="Welcome">
                    <g>
                      <circle
                        className="cls-2"
                        cx="132.52"
                        cy="132.52"
                        r="130.52"
                      />
                      <path
                        className="cls-1"
                        d="M91.31,130.65v-57.05c0-3.98,3.22-7.2,7.2-7.2,1.5,0,2.89.46,4.04,1.24l97.85,56.79c3.43,1.99,4.61,6.39,2.62,9.82-.67,1.15-1.61,2.05-2.69,2.66l-98.21,57c-3.43,1.99-7.83.82-9.82-2.62-.66-1.14-.97-2.38-.97-3.6h-.02v-57.05h0Z"
                      />
                    </g>
                  </g>
                </svg>
              </button>
              <img src={Logo} alt="logo" className="mx-auto" width="340px" />
            </div>
          </motion.div>
        )}
        {isRunning && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="question-board"
          >
            <Questions
              onEnd={() => {
                setIsRunning(false);
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default App;
