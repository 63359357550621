import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Carousel1 from "./assets/carousel-1.png";
import Carousel2 from "./assets/carousel-2.png";
import Carousel3 from "./assets/carousel-3.png";
import Carousel4 from "./assets/carousel-4.png";
import Carousel5 from "./assets/carousel-5.png";
let CINTERVAL: ReturnType<typeof setTimeout> | undefined = undefined;
const Carousel = () => {
  const [positionIndexes, setPositionIndexes] = useState([0, 1, 2, 3, 4]);
  const handleNext = () => {
    setPositionIndexes((prev) => {
      return prev.map((_, i) => (_ + 1) % 5);
    });
  };
  const images = [Carousel1, Carousel2, Carousel3, Carousel4, Carousel5];
  const imageVariants = {
    center: {
      x: 0,
      scale: 1.1,
      zIndex: 3,
      rotate: "0deg",
    },
    left1: {
      x: "-99%",
      scale: 0.7,
      opacity: 0.75,
      zIndex: 2,
      // rotateY: '-30deg'
    },
    left: {
      x: "0%",
      scale: 0.4,
      opacity: 0,
      zIndex: 1,
      // rotateY: '-60deg'
    },
    right1: {
      x: "99%",
      scale: 0.7,
      opacity: 0.75,
      zIndex: 2,
      // rotateY: '60deg'
    },
    right: {
      x: "0",
      scale: 0.4,
      opacity: 0,
      zIndex: 1,
      // rotateY: '120deg'
    },
  };
  useEffect(() => {
    CINTERVAL = setTimeout(() => {
      handleNext();
    }, 2200);
    return () => {
      clearTimeout(CINTERVAL);
    };
  }, [positionIndexes]);
  const positions = ["center", "left1", "left", "right", "right1"];
  return (
    <div className="carousel">
      {images.map((image, i) => {
        return (
          <motion.img
            key={i}
            src={image}
            alt=""
            initial="center"
            animate={positions[positionIndexes[i]]}
            transition={{ duration: 2.3 }}
            style={{ width: "60%", position: "absolute" }}
            variants={imageVariants}
          />
        );
      })}
    </div>
  );
};
export default Carousel;
